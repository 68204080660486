.App {
  text-align: center;
  font-family: 'Satoshi', 'Roboto', 'Helvetica Neue', sans-serif;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  color: white;
  padding: 16px;
}

.App-main {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-icon {
  width: 10%;
}

.App-screenshot {
  width: 70%;
  height: auto;
  aspect-ratio: 16 / 10;
}

.Divider {
  width: 100%;
  max-width: 960px;
  height: 1px;
  background-color: #ccc;
  margin: 1rem 0;
}

.section {
  padding: 24px;
}

.footer {
  font-size: 18px;
}